import React, { useState, useEffect, useRef } from 'react';
import Viewer from 'react-viewer';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ApiService, attachs as attachNames } from 'services';
import MatriculateData from 'components/Requests/MatriculateData';
import { useAlertPlus } from 'hooks';
import config from 'config.js';
import Comments from './Comments';
import Logs from 'components/Logs';
import AlertModal from 'components/AlertModal';
import './Edit.scss';
import { useAlertError } from 'hooks';
import AlertError from 'components/AlertError';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import { RawRequest, RawSession, Observation } from '@cokiba/types';
import { getErrorMessage } from 'utils/errors';
import AffiliateData from 'components/Requests/AffiliateData';
import PracticeData from 'components/Requests/PracticeData';
import SessionsEditor from 'components/Requests/SessionsEditor';
import MiscEditor from 'components/Requests/MiscEditor';
import NewSession from 'components/Requests/NewSession';
import moment from 'moment';

registerLocale('es', es);

type RequestReplacements =
  | 'sessionDates'
  | 'authorization_date'
  | 'fecha_prescripcion_medica';

interface ParsedSession extends Omit<RawSession, 'date'> {
  date: Date;
}

export interface ParsedRequest extends Omit<RawRequest, RequestReplacements> {
  sessionDates: ParsedSession[];
  authorization_date: Date | null;
  fecha_prescripcion_medica: Date | null;
}

interface GalleryItem {
  src: string;
  downloadUrl: string;
  alt: string;
}

function Editor() {
  const location = useLocation();

  const diffDays = location.state?.diffDays ?? null;

  const { id: rawId } = useParams();

  if (!rawId) {
    window.location.href = '/solicitudes';
    return <></>;
  }

  const id = parseInt(rawId);

  const [observations, setObservations] = useState<Observation[]>([]);

  const [gallery, setGallery] = useState<GalleryItem[]>([]);
  const galleryContainer = useRef(null);

  const [alertShow, alertMessage, alertCallback, alert] = useAlertPlus('');
  const [showError, messageError, detailsError, callbackError, alertError] =
    useAlertError();

  const [isLoading, setLoading] = useState(false);

  const [request, setRequest] = useState<ParsedRequest>();
  const [updateData, setUpdateData] = useState<Partial<ParsedRequest>>({});

  const parseRequest = (res: RawRequest): ParsedRequest => {
    const newRequest: ParsedRequest = Object.assign(
      {},
      {
        sessionDates: [],
        id: 0,
        createdAt: '',
        updatedAt: '',
        estado_id: 0,
        ooss_id: null,
        matriculado_id: null,
        numero_afiliado: null,
        recipient_validated: null,
        bsoId: null,
        remito_id: null,
        diagnostico: null,
        prestacion_discapacidad: false,
        es_refacturacion: false,
        idTipoPractica: null,
        tipo_practica: null,
        practice_code: null,
        idPracticaAdicional: null,
        practica_adicional: null,
        additional_practice_code: null,
        quirurgical: null,
        numero_aprobacion: '',
        authorization_transaction_id: null,
        fecha_prescripcion_medica: null,
        cantidad_sesiones: 0,
        recipient_token: null,
        exceptional: null,
        exceptional_text: null,
        fecha_aprobacion: null,
        fecha_ultima_observacion: null,
        fecha_procesamiento: null,
        aprobacion_previa: null,
        aprobacion_validada: null,
        matricula_profesional_solicitante: null,
        createdRegister: '',
        updatedRegister: '',
        authorization_date: null,
        deletedAt: null,
        observations: [],
        attachs: [],
        observation: null,
      },
      res,
    );

    newRequest.sessionDates = res.sessionDates.map(session => ({
      ...session,
      date: moment(session.date, 'YYYY-MM-DD').toDate(),
    }));

    if (res.authorization_date) {
      newRequest.authorization_date = moment(res.authorization_date, 'YYYY-MM-DD').toDate();
    }

    if (res.fecha_prescripcion_medica) {
      newRequest.fecha_prescripcion_medica = moment(res.fecha_prescripcion_medica, 'YYYY-MM-DD').toDate();
    }

    return newRequest;
  };

  useEffect(() => {
    if (!id) {
      return;
    }

    setLoading(true);
    ApiService.getOne('requests', id)
      .then((res: RawRequest) => {
        const newRequest = parseRequest(res);
        setRequest(newRequest);

        const { attachs, observations } = res;

        if (attachs) {
          setGallery(
            attachs.map(attach => {
              const parts = attach.url.split('/');
              parts[parts.length - 1] = encodeURIComponent(
                parts[parts.length - 1],
              );
              attach.url = parts.join('/');

              return {
                src:
                  attach.tipo_archivo !== 'pdf'
                    ? `${config.baseUrl}/${attach.url}`
                    : require('../../assets/img/pdficon.png'),
                downloadUrl: `${config.baseUrl}/${attach.url}`,
                // @ts-ignore
                alt: attachNames[attach.tipo],
              };
            }),
          );
        }

        if (observations) {
          setObservations(observations);
        }
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const handleDelete = async () => {
    try {
      const token = localStorage.getItem('accessToken');

      if (!token) {
        return;
      }

      setLoading(true);

      const response = await fetch(
        `${config.baseUrl}/requests/${id}/admin_delete`,
        {
          method: 'POST',
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.status === 401 || response.status === 403) {
        alertError(
          'No se tienen los permisos necesarios para realizar la operación',
        );
        return;
      }

      const body = await response.json();

      if (body.status !== 'ok') {
        if (body.message && body.error) {
          alertError(body.message, body.error);
          return;
        }

        alertError(
          'Error desconocido intentando realizar la operación',
          response,
        );
        return;
      }

      window.location.href = '/admin/solicitudes';
    } catch (err) {
      console.error(err);
      alertError(getErrorMessage(err));
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('accessToken');

      if (!token) {
        return;
      }

      setLoading(true);

      const newData: Record<string, unknown> = updateData;

      if (updateData.fecha_prescripcion_medica) {
        newData.fecha_prescripcion_medica = updateData.fecha_prescripcion_medica
          .toISOString()
          .split('T')[0];
      }

      const query = await fetch(`${config.baseUrl}/requests/admin/${id}`, {
        method: 'PUT',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newData),
      });

      const response = await query.json();

      if (!response.status || response.status !== 'ok') {
        alert(
          `Se produjo un error al intentar actualizar la solicitud: ${JSON.stringify(
            response,
          )}`,
        );
        return;
      }

      alert(`Solicitud actualizada correctamente`);
      setUpdateData({});
    } catch (err) {
      console.error(err);
      alert(getErrorMessage(err));
    } finally {
      setLoading(false);
    }
  };

  const updateRequest = (request: RawRequest) => {
    const newRequest = parseRequest(request);
    setRequest(newRequest);
  };

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">
                      Solicitud #{id}
                      {diffDays != null &&
                        (diffDays >= 60
                          ? ' - [Solicitud vencida]'
                          : ` - [Restan ${
                            60 - diffDays
                          } días para que se venza la solicitud]`)}
                    </h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Link to="/admin/solicitudes">
                      <Button color="primary" size="md">
                        Volver
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm={6}>
                    {request?.matriculate ? (
                      <>
                        <h6 className="heading-small text-muted mb-4">
                          Datos del matriculado
                        </h6>
                        <MatriculateData matriculate={request.matriculate} />
                        <hr className="my-4" />
                      </>
                    ) : null}

                    {request ? (
                      <>
                        <h6 className="heading-small text-muted mb-4">
                          Información de la prestación
                        </h6>
                        <PracticeData request={request} />
                        <hr className="my-4" />
                      </>
                    ) : null}

                    {request ? (
                      <>
                        <h6 className="heading-small text-muted mb-4">
                          Información de las sesiones
                        </h6>
                        <SessionsEditor
                          request={request}
                          updateRequest={updateRequest}
                          setLoading={setLoading}
                        />
                        <hr className="my-4" />
                      </>
                    ) : null}

                    {request ? (
                      <>
                        <h6 className="heading-small text-muted mb-4">
                          Agregar Sesiones
                        </h6>
                        <NewSession
                          request={request}
                          updateRequest={updateRequest}
                          alertError={alertError}
                        />
                        <hr className="my-4" />
                      </>
                    ) : null}
                  </Col>
                  <Col sm={6}>
                    {request ? (
                      <>
                        <h6 className="heading-small text-muted mb-4">
                          Datos del beneficiario
                        </h6>
                        <AffiliateData request={request} />
                        <hr className="my-4" />
                      </>
                    ) : null}

                    {request ? (
                      <>
                        <h6 className="heading-small text-muted mb-4">
                          Otros datos
                        </h6>
                        <MiscEditor
                          isLoading={isLoading}
                          request={request}
                          setRequest={setRequest}
                          updateData={updateData}
                          setUpdateData={setUpdateData}
                          alertError={alertError}
                        />
                        <hr className="my-4" />
                      </>
                    ) : null}

                    <label className="form-control-label">Adjuntos</label>
                    <div
                      id="gallery-container"
                      style={{ height: '80vh' }}
                      ref={galleryContainer}
                    >
                      {Array.isArray(gallery) &&
                      gallery.length &&
                      galleryContainer.current ? (
                            <Viewer
                              container={galleryContainer.current}
                              noClose={true}
                              visible={true}
                              disableMouseZoom={true}
                              downloadable={true}
                              disableKeyboardSupport={true}
                              zoomSpeed={0.5}
                              downloadInNewWindow={true}
                              noImgDetails={true}
                              scalable={false}
                              showTotal={false}
                              defaultScale={1.25}
                              defaultImg={{
                                src: require('../../assets/img/404.png'),
                              }}
                              images={gallery}
                            />
                          ) : null}
                    </div>
                  </Col>
                </Row>
                <Row className={!observations.length ? 'hidden' : ''}>
                  <Col>
                    <hr className="my-4" />
                    <h6 className="heading-small text-muted mb-4">
                      Observaciones
                    </h6>
                    <div className="pl-lg-4 mb-4">
                      {observations.map(obs => (
                        <p className="mt-2" key={obs.id}>
                          {obs.source === 'auditor' ? (
                            <i className="fas fa-angle-double-right"></i>
                          ) : (
                            <i className="fas fa-angle-double-left"></i>
                          )}
                          &nbsp;
                          {obs.text.split(/\r?\n/).map(line => (
                            <>
                              {line}
                              <br />
                            </>
                          ))}
                          &nbsp;
                          <small>
                            {new Date(obs.createdAt).toLocaleString('es')}
                          </small>
                        </p>
                      ))}
                    </div>
                  </Col>
                </Row>
                <Comments requestId={id} />
                <Logs resource="request" id={id} open={true} />
                <Row>
                  <Col className="text-center">
                    <Button
                      type="button"
                      color="warning"
                      className="btn-round mr-4"
                      onClick={handleDelete}
                      disabled={
                        isLoading ||
                        (request?.estado_id &&
                          ![1, 2, 3, 4].includes(request.estado_id)) ||
                        !!request?.sessionDates.length
                      }
                    >
                      Borrar
                    </Button>

                    <Button
                      type="button"
                      color="primary"
                      className="btn-round mr-4"
                      onClick={handleSave}
                      disabled={isLoading}
                    >
                      Guardar
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <AlertModal
        isOpen={alertShow}
        message={alertMessage}
        onClose={alertCallback}
      />
      <AlertError
        isOpen={showError}
        message={messageError}
        details={detailsError}
        onClose={callbackError}
      />
    </>
  );
}

export default Editor;
