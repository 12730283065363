import React, { useState, useEffect, ChangeEvent } from 'react';
import CollapsibleCard from '../../components/CollapsibleCard';
import { Col, Row, FormGroup, Input, Label } from 'reactstrap';
import { delegations } from '../../services';
import ApiService from 'services/ApiService';
import { useAlertPlus } from 'hooks';
import AlertModal from 'components/AlertModal';
import { UserOptions, useUser } from 'context/UserContext';
import { Insurer } from '@cokiba/types';

interface FiltersProps {
  changeFilter: (key: string, value: unknown) => void;
  delegaciones?: boolean;
  currentOptions: UserOptions['requests'];
}

export default function Filters(props: FiltersProps) {
  const user = useUser();

  const { changeFilter, delegaciones = true, currentOptions } = props;

  const [alertShow, alertMessage, alertCallback, alert] = useAlertPlus('');
  const [insurers, setInsurers] = useState<Insurer[]>([]);

  useEffect(() => {
    ApiService.getAll('ooss', {
      sort: {
        field: 'codigo',
        dir: 1,
      },
    })
      .then(res => {
        setInsurers(res.rows);
      })
      .catch(err => {
        alert('Ha ocurrido un error al obtener el listado de Obras Sociales, ' +
          'temporalmente no se podrá filtrar por obra social.');
        console.error(err);
      });
  }, []);

  return (
    <CollapsibleCard
      title="Filtros"
      open={
        (currentOptions?.delegation && currentOptions?.delegation !== 'any') ||
        currentOptions?.estado_id !== '[2, 3]' ||
        (currentOptions?.ooss_id && currentOptions?.ooss_id !== 'any')
      }>
      <Row>
        <Col md="3">
          <FormGroup>
            <label>Condición</label>
            <Input
              type="select"
              onChange={ event => changeFilter('vencimiento', event.target.value) }
              value={ currentOptions?.vencimiento ? currentOptions.vencimiento : 'any' }
            >
              <option value="any">Todos</option>
              <option value="30">Menores a 30 días</option>
              <option value="60">Entre 30 y 60 días</option>
              <option value="-1">Vencidas</option>
            </Input>
          </FormGroup>
        </Col>
        {
          delegaciones &&
          <Col md="3">
            <FormGroup>
              <Label>Delegaciones</Label>
              <Input
                type="select"
                onChange={ (event: ChangeEvent<HTMLInputElement>) => changeFilter('delegation', event.target.value) }
                value={ currentOptions?.delegation ? currentOptions.delegation : 'any' }
              >
                <option key="any" value="any">Todas</option>
                {
                  Object
                    .entries(delegations)
                    .map(([key, value]) => <option key={key} value={key}>{value}</option>)
                }
              </Input>
            </FormGroup>
          </Col>
        }
        <Col md="3">
          <FormGroup>
            <label>Estado</label>
            <Input
              type="select"
              onChange={ (event: ChangeEvent<HTMLInputElement>) => changeFilter('estado_id', event.target.value) }
              value={ currentOptions?.estado_id ? currentOptions.estado_id : '[2, 3]' }
            >
              {
                user.is([1, 2, 3])
                  ? <>
                      <option key="any" value="any">Todos</option>
                      <option key="1" value="1">Borradores</option>
                    </>
                  : <option key="any" value="[2, 3, 4, 5, 6, 7, 8, 9, 10]">Todos</option>
              }
              <option value="[2, 3]">Activas</option>
              <option value="2">Solicitada</option>
              <option value="3">Auditada</option>
              <option value="4">Observada</option>
              <option value="5">Procesada</option>
              <option value="6">Pedido Factura</option>
              <option value="7">Pendiente Recibo</option>
              <option value="8">Presentada</option>
              <option value="10">Fuera de término</option>
            </Input>
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <label>Obra Social</label>
            <Input
              type="select"
              onChange={ (event: ChangeEvent<HTMLInputElement>) => changeFilter('ooss_id', event.target.value) }
              value={ currentOptions?.ooss_id ? currentOptions.ooss_id : 'any' }
            >
              <option value="any">Todas</option>
              {
                insurers && Array.isArray(insurers)
                  ? insurers.map(insurer => (
                    <option key={'insurer_' + insurer.id} value={ insurer.id }>{ insurer.codigo }</option>
                  ))
                  : null
              }
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <AlertModal isOpen={ alertShow } message={ alertMessage } onClose={ alertCallback } />
    </CollapsibleCard>
  );
}
